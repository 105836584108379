module.exports = {
  pathPrefix: '/',
  // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  siteTitle: "samponeill",
  // Navigation and Site Title
  siteTitleAlt: "Sam P O'Neill - Design and development portfolio",
  // Alternative Site title for SEO
  siteHeadline: 'Design systems, art direction & JAM stack websites',
  // Headline for schema.org JSONLD
  siteTitleShort: "Sam P O'Neill",
  // short_name for manifest
  siteUrl: 'https://samponeill.com',
  // Domain of your site. No trailing slash!
  siteLanguage: 'en',
  // Language Tag on <html> element
  siteLogo: '/logo.png',
  // Used for SEO and manifest
  siteDescription: 'UI/UX Designer for hire in Nottingham, UK',
  author: 'SPO Design Co',
  // Author for schema.org JSONLD
  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@samponeill',
  // Twitter Username
  ogSiteName: 'samponeill',
  // Facebook Site Name
  ogLanguage: 'en_UK',
  googleAnalyticsID: 'UA-143359236-1',
  // Manifest and Progress color
  themeColor: '#3498DB',
  backgroundColor: '#2b2e3c'
};